import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

// All the following keys are optional.
// We try our best to provide a great default value.
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#d81b60',
      dark: '#a00037',
      light: '#ff5c8d',
    },
    secondary: {
      main: '#424242',
      dark: '#1b1b1b',
      light: '#6d6d6d',
    },
  },
});

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
      <App />
   </MuiThemeProvider>,
  document.getElementById('root'));

registerServiceWorker();
